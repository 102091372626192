@import "./assets/theme/easypay-theme.css"; /* You can add global styles to this file, and also import other style files */

:root {
   --color-white: #fff;
   --color-soft-white: #e9ecef;
   --color-black: #000;
   --color-grey: #b4b7ba;
   --color-red: #d64958;
   --color-blue: #1d4ed8;
   --color-soft-blue: #eff6ff;
   --color-easypay-green: #56c271;
   --color-easypay-grey: #506d85;
   --color-easypey-blue: #05668d;
   --color-easypay-strong-blue1: #13294b;
   --color-easypay-strong-blue2: #0c2340;
   --color-border: rgb(222, 226, 230);
   --font-muli-medium: Muli-Medium, Arial, Helvetica, sans-serif;
   --font-muli-bold: Muli-Bold, Arial, Helvetica, sans-serif;
   --font-muli-black: Muli-Black, Arial, Helvetica, sans-serif;
}

body {
   box-sizing: border-box;
    margin: 0;
}

/* EasyPay Fonts */
@font-face {
   font-family: Muli-Medium;
   src: url('./assets/fonts/muli/Muli-Medium.ttf');
}

@font-face {
   font-family: Muli-Bold;
   src: url('./assets/fonts/muli/Muli-Bold.ttf');
}

@font-face {
   font-family: Muli-Black;
   src: url('./assets/fonts/muli/Muli-Black.ttf');
}

html {
   font-family: var(--font-muli-medium);
}

h1{
   font-family:var(--font-muli-bold); ;
}

lib-main-layout div.main lib-sidenav div.main-sidenav{

   div.tool-sidenav {

      div.p-element.tool.tool-logo img{
         width: 70px;
      }

      span{
         display: none;
      }
   }

   div.menu-list lib-menu-sidenav div.menu-content p-panelmenu div div.p-panelmenu-panel div div a{
      padding-right: 9.5px;
      color: var(--color-white);

      &.p-menuitem-link-active{
         color: var(--color-easypay-green);
      }
   }
}

div.tool-sidenav span{
   color: var(--color-white);
}

div.main-container{

   lib-toolbar p-toolbar div.p-toolbar{
      background-color: var(--color-easypay-strong-blue2);
   }

   div.content-wrapper div.overflow-container{
      background: rgb(250,249,248);
      background: -moz-linear-gradient(0deg, rgba(250,249,248,1) 35%, rgba(12,35,64,1) 72%);
      background: -webkit-linear-gradient(0deg, rgba(250,249,248,1) 35%, rgba(12,35,64,1) 72%);
      background: linear-gradient(0deg, rgba(250,249,248,1) 35%, rgba(12,35,64,1) 72%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#faf9f8",endColorstr="#0c2340",GradientType=1);
   }

   lib-footer{
      background-color: var(--color-white);
   }
}